{
  "name": "@samknows/design-system",
  "version": "9.6.5",
  "bin": {
    "npm-link-app-to-design-system": "./bin/npm-link-app-to-design-system.sh"
  },
  "main": "src/index.ts",
  "files": [
    "src",
    "typings"
  ],
  "scripts": {
    "lint": "eslint --quiet --no-fix \"./src/**/*.{js,ts,vue}\"",
    "lint:fix": "eslint --fix \"./src/**/*.{js,ts,vue}\"",
    "test": "npm run test:unit",
    "test:watch": "npm run test:unit -- --watch --no-cache",
    "test:unit": "jest",
    "storybook:build": "storybook build",
    "storybook:serve": "storybook dev -p 6006"
  },
  "dependencies": {
    "@panter/vue-i18next": "^0.15.1",
    "@types/accept-language-parser": "^1.5.1",
    "@types/js-cookie": "^2.2.6",
    "accept-language-parser": "^1.5.0",
    "dayjs": "^1.9.6",
    "flexboxgrid2": "^7.2.1",
    "i18next": "^13.1.5",
    "js-cookie": "^2.2.1",
    "normalize.css": "^8.0.1",
    "v-tooltip": "^2.0.3",
    "vue-global-events": "^1.1.2"
  },
  "devDependencies": {
    "@samknows/utils": "*",
    "@storybook/addon-a11y": "~7.0.20",
    "@storybook/addon-essentials": "~7.0.20",
    "@storybook/addons": "~7.0.20",
    "@storybook/blocks": "~7.0.20",
    "@storybook/testing-library": "^0.1.0",
    "@storybook/theming": "~7.0.20",
    "@storybook/vue": "~7.0.20",
    "@storybook/vue-vite": "~7.0.20",
    "@types/jest": "^24.0.19",
    "@vitejs/plugin-vue2": "^2.2.0",
    "@vue/test-utils": "^1.1.3",
    "autoprefixer": "^10.4.14",
    "chromatic": "^5.6.3",
    "fetch-mock": "^9.11.0",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.5.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "sass": "^1.43.4",
    "storybook": "~7.0.20",
    "storybook-addon-rtl": "^0.5.0",
    "ts-jest": "^29.1.0",
    "typescript": "^4.9.4",
    "vite": "^4.3.9",
    "vue": "^2.7.14",
    "vue-jest": "^3.0.7",
    "vue-template-compiler": "^2.7.14",
    "yorkie": "^2.0.0"
  },
  "peerDependencies": {
    "@samknows/utils": "*",
    "vue": "^2.0.0 || >=3.0.0"
  },
  "gitHooks": {
    "pre-push": "npm run lint && npm run test:unit"
  },
  "volta": {
    "node": "16.20.0"
  }
}
