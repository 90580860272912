import { addons } from '@storybook/addons';
import { create } from '@storybook/theming/create';
import { version } from '../package.json';

const theme = create({
  base: 'light',
  colorPrimary: '#1868fb',
  fontBase: '"Calibre", sans-serif',
  brandTitle: `Samknows Design System (${version})`,
  brandUrl: 'https://github.com/SamKnows/front-end-utils'
});

addons.setConfig({
  panelPosition: 'bottom',
  theme
});